import storage from 'src/services/storage'
import { TOKEN } from 'src/helpers/constant'
import router from 'src/router'
import { Auth } from 'aws-amplify'

const state = {
  userInfo: null
}

const getters = {}

const mutations = {
  updateUser(state, item) {
    state.userInfo = item
  }
}

const actions = {
  async login(context, term) {
    storage.set(TOKEN, term)
  },
  logout(context) {
    storage.remove(TOKEN)
    router.push({ name: 'Login' })
    Auth.signOut()
    context.commit('updateUser', null)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
