import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'
import Breadcrumbs from './components/bread_crumbs'
import Permission from './components/Permission'
import { store } from './store'
import Vue2Filters from 'vue2-filters'
import VueSweetalert2 from 'vue-sweetalert2'
import VueFormWizard from 'vue-form-wizard'
import VueTour from 'vue-tour'
import Notifications from 'vue-notification'
import { Vue2Dragula } from 'vue2-dragula'
import Toasted from 'vue-toasted'
import SmartTable from 'vuejs-smart-table'
import { VueMasonryPlugin } from 'vue-masonry'
import VueFeather from 'vue-feather'
import VueApexCharts from 'vue-apexcharts'
import FunctionalCalendar from 'vue-functional-calendar'
import vueKanban from 'vue-kanban'
import i18n from 'src/services/i18n'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import 'src/helpers/vee-validate'
import { Settings } from 'luxon'
import { getCurrentLanguage } from './helpers/functions'
import 'vue-multiselect/dist/vue-multiselect.min.css'
Settings.defaultLocale = getCurrentLanguage()

import PxCard from './components/Pxcard.vue'
Vue.component(PxCard.name, PxCard)

import { Icon } from 'leaflet'
delete Icon.Default.prototype._getIconUrl

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

// Import Theme scss
import './assets/scss/app.scss'

Vue.use(VueFeather)

Vue.use(Toasted, {
  iconPack: 'fontawesome'
})
Vue.use(Vue2Dragula)

Vue.use(Notifications)
Vue.use(Vue2Filters)
Vue.use(VueSweetalert2, {
  confirmButtonText: i18n.t('button.yes'),
  cancelButtonText: i18n.t('button.no'),
  showCancelButton: true,
  reverseButtons: true
})
Vue.use(VueFormWizard)
Vue.use(VueTour)
Vue.use(BootstrapVue)
Vue.use(SmartTable)
Vue.use(require('vue-chartist'))
Vue.use(require('vue-moment'))
Vue.component('Breadcrumbs', Breadcrumbs)
Vue.component('Permission', Permission)
Vue.use(VueMasonryPlugin)
Vue.component('apexchart', VueApexCharts)
Vue.use(FunctionalCalendar, {
  dayNames: ['M', 'T', 'W', 'T', 'F', 'S', 'S']
})
Vue.use(vueKanban)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('datetime', Datetime)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
