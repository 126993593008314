import storage from 'src/services/storage'
import { createAutoResolvePromise } from '@/helpers/functions'

const state = {
  loading: false,
  currentLanguage: storage.get('currentLanguage') || 'en',
  inputPromises: [],
  basicLoadingPromiseData: null,
  basicLoadingCount: 0,
  loadingPromise: null
}

const getters = {}

const mutations = {
  setLoading: (state, data) => {
    state.loading = true
    if (data === true && !state.basicLoadingPromiseData) {
      state.basicLoadingPromiseData = createAutoResolvePromise(10000)
      state.basicLoadingCount++
    } else if (data === false && state.basicLoadingPromiseData) {
      state.basicLoadingCount--
      if (state.basicLoadingCount <= 0) {
        state.basicLoadingCount = 0
        state.basicLoadingPromiseData.resolve()
        state.basicLoadingPromiseData = null
      }
    } else if (Array.isArray(data)) {
      data.forEach(item => {
        state.inputPromises.push(Promise.resolve(item))
      })
    } else {
      state.inputPromises.push(Promise.resolve(data))
    }
    const newPromise = Promise.allSettled(state.basicLoadingPromiseData ? [
      ...state.inputPromises,
      Promise.resolve(state.basicLoadingPromiseData.promise)
    ] : state.inputPromises).then(() => {
      if (newPromise === state.loadingPromise) {
        state.loading = false
        state.inputPromises = []
      }
    })
    state.loadingPromise = newPromise
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
