<template>
  <div id="app">
    <!-- Loader starts-->
    <div class="loader-wrapper" :class="{ loderhide: !isLoading }">
      <div class="loader-box">
        <div class="loader-19" />
      </div>
    </div>
    <!-- Loader ends-->

    <notifications group="notify" />

    <!--<Main/>-->
    <router-view />
  </div>
</template>

<script>
import { store } from "src/store";
import { Amplify, Auth } from "aws-amplify";
const authParams = {
  Auth: {
    region: process.env.VUE_APP_REGION,
    identityPoolRegion: process.env.VUE_APP_REGION,
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    authenticationFlowType: "CUSTOM_AUTH",
  },
};
Amplify.configure(authParams);
export default {
  name: "App",
  metaInfo() {
    return {
      meta: [
        {
          httpEquiv: "Content-Language",
          content: localStorage.getItem("currentLanguage"),
        },
      ],
      title: "CMS | Empaty B2B",
    };
  },
  data() {
    return {};
  },
  computed: {
    isLoading() {
      return store.state.app.loading;
    },
  },
};
</script>

<style lang="scss" scoped>
.loader-wrapper.loderhide {
  display: none;
}
.loader-wrapper {
  opacity: 0.5;
}
.loader-box .loader-19 {
  border-left-color: var(--theme-deafult);
  border-right-color: var(--theme-deafult);
}
</style>
