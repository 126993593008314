import storage from 'src/services/storage'
import {
  PAYMENT_METHODS,
  REQUEST_CANCEL_STATUS,
  STATUS_INVOICE,
  PRODUCT_NAMES,
  PLAN_SUBTYPES,
  SERVICE_TYPE_STRIPE,
  SERVICE_TYPE_GOOGLE_PLAY,
  SERVICE_TYPE_APP_STORE,
  PAYMENT_METHOD,
  MODEL_GOLD,
  LOCALES
} from 'src/helpers/constant'
import i18n from 'src/services/i18n'
import { formatDate as formatDateUtil } from 'element-ui/src/utils/date-util'

export function formatDateType() {
  const lang = storage.get('currentLanguage') || LOCALES.ENGLISH
  if (lang === LOCALES.ENGLISH) {
    return 'MM/dd/yyyy'
  }
  if (lang === LOCALES.JAPANESE) {
    return 'yyyy/MM/dd'
  }
}

export function formatDateTimeType(dateTime = null) {
  const lang = storage.get('currentLanguage') || LOCALES.ENGLISH
  if (dateTime) {
    switch (lang) {
      case LOCALES.ENGLISH:
        return formatDateUtil(dateTime, 'MM/dd/yyyy HH:mm')
      case LOCALES.JAPANESE:
        return formatDateUtil(dateTime, 'yyyy/MM/dd HH:mm')
    }
  } else {
    switch (lang) {
      case LOCALES.ENGLISH:
        return 'MM/dd/yyyy HH:mm'
      case LOCALES.JAPANESE:
        return 'yyyy/MM/dd HH:mm'
    }
  }
}

export function formatUrl(url) {
  if (!url) return ''
  return url.includes('https://') || url.includes('http://') ? url : 'https://' + url
}

export function formatPaymentMethod(value) {
  let text = null
  switch (value) {
    case PAYMENT_METHOD.SUBSCRIPTION_CARD:
      text = i18n.t('card')
      break
    case PAYMENT_METHOD.CASH_TRANSFER:
      text = i18n.t('cash_transfer')
      break
    case PAYMENT_METHOD.IN_APP_PURCHASE:
      text = i18n.t('in_app_purchase')
      break
  }
  return {
    value,
    text
  }
}

export function formatProductName(name, type = null, startDate) {
  switch (type) {
    case PRODUCT_NAMES.SWITCH_TO_YEARLY:
      return i18n.t('product_name_switch_to_yearly', { date: startDate })
    default:
      return name
  }
}

export function formatPlanSubtype(subType) {
  switch (subType) {
    case PLAN_SUBTYPES.BASE:
      return i18n.t('plan_type_base')
    case PLAN_SUBTYPES.LICENSE:
      return i18n.t('plan_type_license')
  }
}

export function formatInvoiceProductName(name, type = null, subtype = null, unixStartDate = null, unixEndDate = null, productType = null) {
  if (productType === MODEL_GOLD) {
    return `${i18n.t('gold')} ${name}`
  }
  const startDate = formatDate(new Date(unixStartDate))
  const endDate = formatDate(new Date(unixEndDate))
  let productName = formatProductName(name, type, startDate)
  if (subtype) {
    productName += ' (' + formatPlanSubtype(subtype) + ')'
  }
  if (unixStartDate && unixEndDate) {
    productName += ' (' + startDate + ' - ' + endDate + ')'
  }

  return productName
}

export function formatDate(date) {
  const lang = localStorage.getItem('currentLanguage') || LOCALES.ENGLISH
  if (lang === LOCALES.ENGLISH) {
    return formatDateUtil(date, 'MM/dd/yyyy')
  }
  if (lang === LOCALES.JAPANESE) {
    return formatDateUtil(date, 'yyyy/MM/dd')
  }
}

export function convertPrice(price, currency) {
  return Number(price).toLocaleString('en-US', {
    style: 'currency',
    currency: currency
  })
}

export function roundCurrency(currency, price) {
  const power = Math.pow(10, new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency
  }).resolvedOptions().maximumFractionDigits)

  return Math.round(price * power) / power
}

export function formatPriceNumber(currency, price, withSymbol = true) {
  if (withSymbol) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(price)
  }

  const currencyFractionDigits = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency
  }).resolvedOptions().maximumFractionDigits
  console.log(new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency
  }).resolvedOptions())

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: currencyFractionDigits,
    maximumFractionDigits: currencyFractionDigits
  }).format(price)
}

export function formatStatusInvoice(status, paymentMethod, requestCancelStatus) {
  let textStatus = ''
  let textClass = ''
  switch (status) {
    case STATUS_INVOICE.OPEN:
      textStatus = i18n.t('status_invoice.open')
      textClass = 'badge-light-warning'
      break
    case STATUS_INVOICE.PAID:
      textStatus = i18n.t('status_invoice.paid')
      if (paymentMethod === PAYMENT_METHODS[0].value) {
        textStatus += ` - ${i18n.t('status_invoice.subscription_card')}`
      }
      if (paymentMethod === PAYMENT_METHODS[1].value) {
        textStatus += ` - ${i18n.t('status_invoice.cash_transfer')}`
      }
      if (paymentMethod === PAYMENT_METHODS[2].value) {
        textStatus += ` - ${i18n.t('in_app_purchase')}`
      }
      textClass = 'badge-light-success'
      break
    case STATUS_INVOICE.CANCEL:
      textStatus = i18n.t('status_invoice.cancel')
      textClass = 'badge-light-info'
      break
    case STATUS_INVOICE.UNPAID:
      textStatus = i18n.t('status_invoice.unpaid')
      textClass = 'badge-light-danger'
      break
    case STATUS_INVOICE.CARD_ERROR:
      textStatus = i18n.t('status_invoice.card_error')
      textClass = 'badge-light-danger'
      break
  }

  if (requestCancelStatus === REQUEST_CANCEL_STATUS.PENDING) {
    textStatus = `${i18n.t('status_invoice.request_cancel')} (${textStatus})`
    textClass = 'badge-light-danger'
  }
  return {
    status: textStatus,
    class: textClass
  }
}

export function getCurrentLanguage() {
  return storage.get('currentLanguage')
}

export function createAutoResolvePromise(timer = 5000) {
  let outputResolve
  const promise = new Promise(resole => {
    outputResolve = () => resole()
    setTimeout(() => resole(), timer)
  })

  return {
    promise,
    resolve: outputResolve
  }
}

export function showTotalMoney(data) {
  const { gold, totalInvoice } = data
  let totalMoneyCard = 0
  let totalMoneyAndroid = 0
  let totalMoneyApple = 0
  let totalPurchases = 0

  let currency = ''
  totalInvoice.forEach(item => {
    switch (item.service) {
      case SERVICE_TYPE_STRIPE:
        totalMoneyCard = formatPriceNumber(item.currency, item.total)
        break
      case SERVICE_TYPE_GOOGLE_PLAY:
        totalMoneyAndroid = formatPriceNumber(item.currency, item.total)
        break
      case SERVICE_TYPE_APP_STORE:
        totalMoneyApple = formatPriceNumber(item.currency, item.total)
        break
    }
    currency = item.currency
    totalPurchases += parseFloat(item.total)
  })
  totalPurchases = totalPurchases > 0 ? formatPriceNumber(currency, totalPurchases) : 0

  return {
    totalMoneyCard,
    totalMoneyAndroid,
    totalMoneyApple,
    totalPurchases
  }
}

export function getCompanyName(user) {
  if (user.organization && user.organization.name) {
    return user.organization.name
  } else if (user.business && user.business.company) {
    return user.business.company
  }
  return ''
}
