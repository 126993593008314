<template>
  <div>
    <div class="container-fluid">
      <div class="page-title">
        <div class="row">
          <div class="col-6 d-flex">
            <h3>{{ title }}</h3>
          </div>
          <div class="col-6">
            <ol class="breadcrumb pull-right">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'Home' }">
                  <feather type="home" />
                </router-link>
              </li>
              <li v-if="main && mainRoute" class="breadcrumb-item">
                <router-link v-if="mainRoute" :to="{ path: mainRoute }">{{
                  main
                }}</router-link>
              </li>
              <li v-if="main && !mainRoute" class="breadcrumb-item">
                {{ main }}
              </li>
              <li v-if="title" class="breadcrumb-item active">
                {{ title }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: "home",
    },
    main: {
      default: "",
    },
    mainRoute: {
      default: "",
    },
  },
};
</script>
