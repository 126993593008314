import { extend } from 'vee-validate'
import {
  required,
  confirmed
} from 'vee-validate/dist/rules'
import i18n from 'src/services/i18n'

extend('required', {
  ...required,
  message: i18n.t('validate.required')
})
extend('email', {
  validate(value) {
    return String(value.trim())
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  },
  message: i18n.t('validate.email')
})
extend('minmax', {
  validate(value, { min, max }) {
    return value.length >= min && value.length <= max
  },
  params: ['min', 'max'],
  message: i18n.t('validate.minmax')
})
extend('confirmed', {
  ...confirmed,
  message: i18n.t('validate.confirmed')
})
