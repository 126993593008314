import en from "src/locales/en.json";
import ja from "src/locales/ja.json";
import { defaultLocale, localeOptions } from "src/constants/config";
import Vue from "vue";
import VueI18n from "vue-i18n";
import storage from "src/services/storage";

Vue.use(VueI18n);
const messages = { en: en, ja: ja };
const locale =
  storage.get("currentLanguage") &&
  localeOptions.filter((x) => x.id === storage.get("currentLanguage")).length >
    0
    ? storage.get("currentLanguage")
    : defaultLocale;
const datetimeFormats = {
  en: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
  },
  ja: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    },
  },
};
export default new VueI18n({
  locale: locale,
  fallbackLocale: "en",
  silentTranslationWarn: true,
  messages,
  datetimeFormats,
});
