import { TOKEN } from "src/helpers/constant";
import storage from "src/services/storage";
import jwt_decode from "jwt-decode";
export async function auth(to, from, next) {
  const token = storage.get(TOKEN);
  if (token) {
    let expire_time = jwt_decode(token).exp * 1000;
    let exp = new Date(expire_time).getTime();
    let current_time = new Date().getTime();
    if (current_time > exp) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    next({ name: "Login" });
  }
}

export function guest(to, from, next) {
  storage.get(TOKEN) ? next({ name: "Home" }) : next();
}
