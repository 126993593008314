import i18n from "src/services/i18n";

export const TOKEN = "token";

export const LOCALES = {
  ENGLISH: "en",
  JAPANESE: "ja",
};

export const LOCALES_TO_LANGUAGE = {
  [LOCALES.ENGLISH]: LOCALES.ENGLISH,
  [LOCALES.JAPANESE]: "jp",
};

export const CONVERSATION_STATUS = {
  ACTIVE: 1,
  DELETE: 2,
};

export const USER_STATUS = {
  ALL: 0,
  ACTIVE: 1,
  STOP: 2,
  BLOCK: 3,
  INVITATION: 4,
  DELETE: 5,
  LEAVE: 6,
};

export const GENDER = {
  MALE: 0,
  FEMALE: 1,
  NON_BINARY: 2,
};

export const CONTACT_TYPE = {
  BLOCK: 1,
  FOLLOW: 2,
  CONTACT: 3,
  CONNECT: 4,
  SCAN_CARD: 5,
  QR_CODE: 6,
  REQUEST_CONNECT: 7,
  IMPORT_CONTACT: 8,
};

export const COUNTRY_CODE = {
  ENGLISH: 1,
  JAPAN: 81,
  VIETNAM: 84,
};

export const LANGUAGES = [
  { id: 1, code: "en", name: "English", flag: "flag-icon-us" },
  { id: 2, code: "ja", name: "Japan", flag: "flag-icon-jp" },
];

export const GENDERS = [
  { value: 0, text: i18n.t("male") },
  { value: 1, text: i18n.t("female") },
  { value: 2, text: i18n.t("non_binary") },
];

export const FILTER_LANGUAGES = [
  { id: "en", name: "English" },
  { id: "ja", name: "Japan" },
];

export const INVITE = {
  EMAIL: "email",
  PHONE: "phone",
};

export const PAYMENT_METHOD = {
  SUBSCRIPTION_CARD: 1,
  CASH_TRANSFER: 2,
  IN_APP_PURCHASE: 3,
};

export const PAYMENT_METHODS = [
  {
    value: PAYMENT_METHOD.SUBSCRIPTION_CARD,
    text: i18n.t("subscription_card"),
  },
  { value: PAYMENT_METHOD.CASH_TRANSFER, text: i18n.t("cash_transfer") },
  { value: PAYMENT_METHOD.IN_APP_PURCHASE, text: i18n.t("in_app_purchase") },
];

export const PAYMENT_METHODS_ORG = [
  {
    value: PAYMENT_METHOD.SUBSCRIPTION_CARD,
    text: i18n.t("subscription_card"),
  },
  { value: PAYMENT_METHOD.CASH_TRANSFER, text: i18n.t("cash_transfer") },
];

export const PAYMENT_TYPES = [
  { value: 1, text: i18n.t("payment_screen.monthly") },
  { value: 2, text: i18n.t("payment_screen.yearly") },
];

export const COMPANY_STATUS = {
  ACTIVE: 1,
  PENDING: 2,
  INACTIVE: 3,
  CANCEL: 4,
  STOP: 5,
};

export const STATUS_INVOICE = {
  OPEN: 1,
  PAID: 2,
  CANCEL: 3,
  UNPAID: 4,
  CARD_ERROR: 5,
  PAID_SUBSCRIPTION_CARD: 6,
  PAID_CASH_TRANSFER: 7,
  REQUEST_CANCEL: 8,
  PAID_IN_APP_PURCHASE: 9,
};

export const STATUS_INVOICE_LIST = [
  { name: i18n.t("status_invoice.open"), value: STATUS_INVOICE.OPEN },
  {
    name: i18n.t("status_invoice.paid_subscription_card"),
    value: STATUS_INVOICE.PAID_SUBSCRIPTION_CARD,
  },
  {
    name: i18n.t("status_invoice.paid_cash_transfer"),
    value: STATUS_INVOICE.PAID_CASH_TRANSFER,
  },
  {
    name: i18n.t("status_invoice.paid_in_app_purchase"),
    value: STATUS_INVOICE.PAID_IN_APP_PURCHASE,
  },
  { name: i18n.t("status_invoice.cancel"), value: STATUS_INVOICE.CANCEL },
  { name: i18n.t("status_invoice.unpaid"), value: STATUS_INVOICE.UNPAID },
  {
    name: i18n.t("status_invoice.card_error"),
    value: STATUS_INVOICE.CARD_ERROR,
  },
  {
    name: i18n.t("status_invoice.request_cancel"),
    value: STATUS_INVOICE.REQUEST_CANCEL,
  },
];

export const STATUS_CHANGE_INVOICES = [
  {
    name: i18n.t("status_invoice.open"),
    value: STATUS_INVOICE.OPEN,
    disabled: true,
  },
  {
    name: i18n.t("status_invoice.paid"),
    value: STATUS_INVOICE.PAID,
    disabled: false,
  },
  {
    name: i18n.t("status_invoice.unpaid"),
    value: STATUS_INVOICE.UNPAID,
    disabled: false,
  },
];

export const REQUEST_CANCEL_STATUS = {
  NONE: 0,
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
};

export const PLAN_SUBTYPES = {
  BASE: "base",
  LICENSE: "license",
};

export const PRODUCT_NAMES = {
  PLAN: "plan",
  STORAGE: "storage",
  SWITCH_TO_YEARLY: "switch_to_yearly",
};

export const FILE_MESSAGE_SIZE_MAX = 25 * 1024 * 1024;

export const TYPE_SCHEDULE_MESSAGE = {
  MESSAGE: 1,
  POPUP: 2,
};

export const STATUS_SCHEDULE_MESSAGE = {
  PENDING: 1,
  SENT: 2,
  CANCEL: 3,
  ERASE: 4,
};

export const TYPE_MESSAGE = [
  {
    value: TYPE_SCHEDULE_MESSAGE["MESSAGE"],
    text: i18n.t("send_message_screen.message"),
  },
  {
    value: TYPE_SCHEDULE_MESSAGE["POPUP"],
    text: i18n.t("send_message_screen.popup_message"),
  },
];

export const FREE_USER = {
  value: 1,
  text: i18n.t("free_user"),
};

export const CHANNEL_TYPE = {
  PR_CHANNEL: 1,
  MY_CHANNEL: 2,
};

export const CHANNEL_PRIVACY = [
  { value: 5, key: "only_me" },
  { value: 2, key: "follower" },
  { value: 1, key: "anyone" },
];

export const STATUS = [
  { value: true, key: "on" },
  { value: false, key: "off" },
];

export const SERVICE_TYPE_STRIPE = "stripe";
export const SERVICE_TYPE_GOOGLE_PLAY = "google_play";
export const SERVICE_TYPE_APP_STORE = "ios_app_store";

export const MODEL_GOLD = "App\\Models\\Gold";

export const POSTCODE_SLICE_INDEX = 3;

export const ADMIN_PERMISSION = {
  VIEW_LIST: 1,
  VIEW_DETAIL: 2,
  EDIT: 3,
  DELETE: 4,
  // ADMIN: 9999,
};
