<template>
  <div>
    <!-- page-wrapper Start-->
    <div class="page-wrapper">
      <!-- error-404 start-->
      <div class="error-wrapper">
        <div class="container">
          <div class="error-heading">
            <div class="col-md-8 offset-md-2">
              <h4 class="font-danger">
                You do not have permission to access this page!
              </h4>
            </div>
            <div>
              <router-link class="btn btn-danger-gradien btn-lg" :to="{ path: mainRoute }">
                {{ $t('button.back') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- error-404 end-->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    mainRoute: {
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.error-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 50vh !important
}
</style>