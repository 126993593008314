import Vue from 'vue'
import Vuex from 'vuex'
import layout from 'src/store/modules/layout'
import menu from 'src/store/modules/menu'
import user from 'src/store/modules/user'
import app from 'src/store/modules/app'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {},
  mutations: {
    changeLang (state, payload) {
      localStorage.setItem('currentLanguage', payload.id)
      localStorage.setItem('currentLanguageIcon', payload.icon)
      window.location.reload()
    }
  },
  actions: {
    setLang ({ commit }, payload) {
      commit('changeLang', payload)
    }
  },
  modules: {
    layout,
    menu,
    user,
    app
  }
})

